.console {
  font-family: $font-mono;
  background-color: $medium-gray;
  border-right: 1px solid $dark-gray;
  padding: 25px;
  height: calc(100vh - 93px);
  overflow-y: scroll;
  color: $text;
  font-size: 0.75rem;

  .console__command-list {
    list-style: none;
    margin: 0;
    padding: 0;

    .console__item {
      padding: inherit;
      line-height: 130%;
      white-space: pre-wrap;

      &:not(.console__item--command) {
        margin-left: 2ch;
      }

      &.console__item--error {
        color: #e74c3c;
      }

      &.console__item--response {
        color: transparentize($text, 0.3);
      }

      &.console__item--light {
        color: transparentize($text, 0.5);
      }

      &.console__item--bold {
        font-weight: 700;
        color: transparentize($text, 0.25);
      }

      &.console__item--warning {
        color: #e67e22;
      }
    }
  }

  .console__prompt {
    background-color: transparent;
    font-family: $font-mono;
    outline: none;
    border: none;
    font-size: 0.75rem;
    margin: 0;
    padding: 0;
    color: $text;
    width: 90%;
    line-height: 100%;
  }
}
