.nav {
  .nav__link {
    cursor: pointer;
    user-select: none;
    border-radius: 50px;
    padding: 6px 16px;
    font-size: 0.9rem;
    font-weight: $font-bold;
    text-decoration: none;
    color: $gray;

    &.nav__link--active {
      background: $primary;
      color: white;
    }

    &:not(.nav__link--active):hover {
      background: $light-gray;
    }

    &:focus {
      box-shadow: inset 0 0 0 2px $primary;
      outline: none;
    }
  }
}
