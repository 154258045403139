.header {
  position: relative;
  perspective: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
  margin-top: 100px;

  .header__title {
    font-weight: normal;
    font-size: 2rem;
    font-weight: $font-bolder;
    flex-grow: 1;
  }

  .header__subtitle {
    font-size: 1.2rem;
    color: $gray;
  }
}
