.resume {
  background: $light-gray;
  padding: 25px;
  height: calc(100vh - 93px);
  overflow-y: scroll;

  .resume__splash {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;

    > div{
      max-width: 450px;

      > * {
        margin-bottom: 0.5rem;
        line-height: 1.7;
      }
    }

    h2 {
      font-weight: $font-bold;
      font-size: 1.3rem;
    }

    code {
      font-family: $font-mono;
      background: $medium-gray;
      border-radius: 2px;
      padding: 2px 4px;
    }
  }
}
