.content {
  margin-bottom: 200px;
  display: block;
  position: relative;
  line-height: 1.5;

  .content__title {
    font-weight: $font-bolder;
    font-size: 1.3rem;
    margin-bottom: 1.3rem;
  }

  .content__subtitle {
    font-weight: $font-bold;
    margin-bottom: 1rem;
  }

  .content__body {
    margin-bottom: 1rem;
  }

  .content__link {
    text-decoration: none;
    color: $text;
    font-weight: $font-bolder;
    position: relative;
    display: inline-block;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 10px;
      background: $light-gray;
      z-index: -2;
      top: 0.75em;
      left: 0px;
    }

    &:after {
      transform: scaleX(0);
      transition: transform $fast-duration ease-in-out;
      background: lighten($primary, 30%);
      transform-origin: right;
    }

    &:hover:after {
      transform: scaleX(1);
      transform-origin: left;
    }

    &.content__link--cyan:after {
      background: lighten(#43CCED, 28%);
    }

    &.content__link--red:after {
      background: lighten(#ff4e21, 35%);
    }

    &.content__link--green:after {
      background: lighten(#4C9689, 35%);
    }

    &.content__link--purple:after {
      background: lighten(#A773A4, 30%);
    }

    &.content__link--blue:after {
      background: lighten(#5AB4F8, 15%);
    }

    &.content__link--dark-blue:after {
      background: lighten(#263d75, 55%);
    }
  }
}
