
body {
  color: $text;
  font-family: $font-sans-serif;
  font-size: 14pt;
  background: linear-gradient(to left bottom, #3c3c3c 0%,#1c1c1c 100%);
  min-height: 100vh;
}

.wrap {
  min-height: 100vh;
  position: relative;

  > .inner-wrap {
    background-color: white;
    z-index: 1000;
    transition:
      transform $slow-duration ease-in-out,
      border-radius $slow-duration ease-in-out,
      background-color $slow-duration ease-in-out;
    transform: translateY(0%);
    transform-origin: 50vw 300px;
    position: absolute;
    width: 100%;
    min-height: 100vh;
  }

  &.interactive-mode {
    overflow: hidden;

    > .inner-wrap {
      overflow: hidden;
      pointer-events: none;
      border-radius: $round-border-radius $round-border-radius 0 0;
      transform: scale(0.92);
      background-color: darken($light-gray, 10%);
    }
  }

  &.crash {
    animation: zoom-out 4s forwards;
    z-index: 1000;
  }
}

.container {
  max-width: $tablet-breakpoint;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  transition: $slow-duration ease-in-out;
}

.link {
  color: $text;
  font-weight: $font-bold;
  text-decoration: underline;
}

main {
  transition: $fast-duration ease-in-out;
}
